<template>
    <!--메인메뉴 패널-->
    <div>
        <div class="mobile-menu-wapper" :class="{'activeMobileMenu':isShowTopMenu}">
            <div class="close" @click="closeMobileMenu()">
                <i class="fa fa-close" aria-hidden="true"></i>
            </div>
            <div class="content">
                <div class="l">
                    <img  src="../../assets/images/logo/ptn/logo01.png">
                </div>
                <mobile-menu-items-comp></mobile-menu-items-comp>
                <right-bar-banner-comp></right-bar-banner-comp>
            </div>
        </div>

    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";
    import MobileMenuItemsComp from "../MobileMenuItemsComp";
    import RightBarBannerComp from "../RightBarBannerComp";

    export default {
        name: "MobileTopMenu",
        components: {RightBarBannerComp, MobileMenuItemsComp},
        data() {
            return {
                sportsConst,
                leisureConst,
                isShowTopMenu: false,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE
            }
        },
        methods: {
            closeMobileMenu(disp) {
                this.isShowTopMenu = false
                console.log(this.isShowTopMenu)
                if (disp) {
                    this.$bus.$emit('sportsDisplayChanged', disp);
                }
            }
        },
        created() {
            this.$bus.$on('mobileTopMenuActive', () => {
                this.isShowTopMenu = true;
            })
        }
    }
</script>

<style scoped>

    /*모바일 메뉴*/
    .mobile-menu-wapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: var(--background);
        z-index: 99999;
        overflow-y: scroll;
        transform: translateX(-100%);
        transition: 0.3s transform;
        box-sizing: border-box;
        padding: 10px 3px;
    }

    .mobile-menu-wapper .content {
        width: 100%;
        height: 100vh;
    }
    .mobile-menu-wapper .content .l{
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
    .mobile-menu-wapper .content .l img{
        height: 40px;
    }
    .logo img{
        width: 100%;
        height: 50px;
    }

    .mobile-menu-wapper .close {
        font-size: 16px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        color: #fff9ff;
        width: 32px;
        height:32px;
        background-color: #c51a1b!important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
    }

    .activeMobileMenu {
        transform: translateX(0%) !important;
    }
</style>