<template>
    <!--오른쪽 메뉴-->
    <div class="user_box">
        <div v-if="$store.state.isLogin">
            <div class="logininfo">
                <div class="infoleft" style="display: flex;align-items: center;justify-content: center">
                    <img :src="$store.state.userInfo.rank.imageUrl" width="80">
                </div>
                <div class="inforight">
                    <div class="line underline" style="display: flex;justify-content: flex-start;align-items: center">
                        <span class="txt-nickname">{{$store.state.userInfo.nickname}}</span>
                        님
                        <router-link tag="button" class="btn-loch ml-5" to="/user_info">회원정보</router-link>
                        <router-link tag="button" class="btn-loch ml-5" to="/message">쪽지</router-link>
                    </div>
                    <div class="line underline text-white2" @click="initUserInfo">
                        머니 <span
                            class="txt-cash">{{$store.state.userInfo.cash|comma}}</span> 원
                        <i class="fa fa-refresh"></i>
                    </div>
                    <div class="line underline text-white2"
                         style="display: flex;justify-content: flex-start;align-items: center">
                        포인트 <span
                            class="txt-cash ml-5">{{$store.state.userInfo.userPoint|comma}}</span> 점
                        <button @click="changePoint" class="btn-loch ml-5">전환
                        </button>
                        <button class="btn-loch ml-10" @click="logout">로그아웃
                        </button>
                    </div>
<!--                    <div class="line txt-cash" style="cursor: pointer;color: #ff973b" @click="refreshCasinoCash()">-->
<!--                        카지노캐쉬 <span-->
<!--                            class="text-white">{{$store.state.userInfo.casinocash|comma}}</span> 원 <i-->
<!--                            class="fa fa-refresh" ></i>-->

<!--                    </div>-->

                </div>
            </div>
        </div>
        <div v-if="!$store.state.isLogin && !$store.state.isMaintaining"
             style="display: flex;justify-content: space-around;align-items: center">
            <button class="btn_login" @click="showLoginPanel">로그인</button>
            <button class="btn_register" @click="showRegisterPanel">회원가입</button>
        </div>
    </div>


</template>

<script>
    import {logout, changePoint, login, getUserInfo} from "../network/userRequest";
    import {getAccountBalance} from "../network/casinoHonorRequest";
    import {
        INIT_USERINFO,
        RECEIVE_HIDE_LOADING,
        RECEIVE_SHOW_LOADING,
        RECEIVE_USER_INFO
    } from "../store/mutation-types";
    import RightBarBannerComp from "./RightBarBannerComp";
    import {loginRegisterMixin} from "../common/mixin";

    export default {
        name: "UserInfoComp",
        mixins: [loginRegisterMixin],
        components: {RightBarBannerComp},
        data() {
            return {
                isShowSubordinate: false,
                user: {
                    username: '',
                    passwd: '',
                },
            }
        },
        methods: {
            subordinate() {
                this.isShowSubordinate = !this.isShowSubordinate;
            },
            showLoginPanel() {

                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    console.log(1111)
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            doLogin() {
                // if (!this.testRegxUserName) {
                //     this.$swal({
                //         title: '아이디는 4-15자리 영문,숫자조합입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                // if (!this.testRegxPasswd) {
                //     this.$swal({
                //         title: '비밀번호는 4-20자리 입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                this.$store.commit(RECEIVE_SHOW_LOADING);
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.state.isLogin = true;
                            const userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            window.sessionStorage.setItem("isLogin", 'true');
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$store.commit(RECEIVE_HIDE_LOADING);
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/login")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            initUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                this.$store.dispatch('actionUserInfo')
                setTimeout(()=>{
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                },1000)
            },
            initUncheckedMessage() {
                this.$store.dispatch('actionUnCheckedMessageCount')
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            refreshCasinoCash() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getAccountBalance().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .user_box {
        width: 100%;
        padding: 0px;
    }

    .user_box .logininfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        --border: 1px solid #dddddd;
        background-color: var(--rightSectionBg);
    }

    .user_box .logininfo .infoleft {
        width: 30%;
    }

    .user_box .logininfo .inforight {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #fff9ff;
        box-sizing: border-box;
        padding-left: 0px;
    }

    .user_box .logininfo .inforight .btn-change-point {
        background-color: #c51a1b;
        padding: 2px 5px;
    }

    .user_box .logininfo .inforight .line {
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: left;
        box-sizing: border-box;
        padding-left: 0px;
        font-weight: bold;
    }

    .user_box .logininfo .inforight .underline {
        --border-bottom: 1px solid #30312c;
    }

    .user_box .btn_login {
        width: 49%;
        height: 34px;

        border-radius: 5px;
        font-weight: 600;
        color: #fff9ff;
        background-color: var(--loginBtnBg);
        border: 2px solid var(--loginBtnBgBorder);
    }

    .user_box .btn_register {
        width: 49%;
        height: 34px;
        border-radius: 5px;
        background-color: var(--regBtnBg);
        --border: 2px solid var(--sportsKindButtonBorderColor);
        font-weight: 600;
        color: #000000;
    }
    .user_box .btn-loch{
        background-color:var(--btn01Bg);
        border-radius: 3px;
        padding: 3px 3px;
        color: var(--btn01Color);
    }

    .user_box .txt-nickname{
        color: #ff9c00;
    }
    .user_box .txt-cash{
        color: #ff973b;
    }


    @media screen and (max-width: 1024px) {
        .user_box {
            --display: none !important;
            padding-top: 8px !important;
        }

        .right_login {
            margin-top: 10px !important;
        }

        .right_login input {
            height: 38px !important;
            line-height: 38px !important;
        }

        .right_login .button_panel button {
            line-height: 38px !important;
        }

    }


</style>