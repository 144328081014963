import sportsConst from "../common/sportsConst";
import localStorage from "./localStorage";
import managerConst from "../common/administrator/managerConst";

export default {
    isMaintaining:false,
    maintainingMessage:'',
    ismobile:false,
    isLogin: false,
    isShowLoginPanel:false,
    isShowRegisterPanel:false,
    isShowTopbar: false,
    isLoading: false,
    isInplayLoading: false,
    iild: false,
    displayPostion: sportsConst.GAME_DISPLAYPOSITION_1x2,
    userInfo: {
        nickname: '',
        cash: 0,
        userPoint: 0,
        casinocash: 0,
        rank: {rankName: ''},
        myReferrer: {},
        referreCode: {},
        subordinateUsers: []
    },
    uncheckedMessageCount: 0,
    commonConfig: {
        rankConfig: {betMinLimitCash: 0, betMaxLimitCash:0},
        bonusConfig: {},
        crossRuleconfig: {}
    },
    sportsCartItems: [],
    inplayCartItems: [],
    sportsOriginal: [],
    sportsLive: [],
    minigames: {},
    selectedGameType: sportsConst.GAME_TYPE_ALL,
    selectedLeagueList: [],
    currentLeisureGameComp:-1,
    currentLeisureGameID:-1,
    popups:[],
    mobileRightMenuStatus:false,

    m:{
        abc:1,
        alarmOn:localStorage.get('alarm', managerConst.YES)
    },
    agent:{
        agentInfo: {
            account: '',
            nickname: '',
            bank:'',
            cash: 0,
            rechargeExchangeProfit: 0,
            sportsBetRollingProfit: 0,
            leisureBetRollingProfit: 0,
            casinoBetRollingProfit: 0,
            slotBetRollingProfit: 0,

        },
    }
}