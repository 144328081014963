import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";

Vue.use(VueRouter)

const router = new VueRouter({
    /* mode: 'history',*/
    routes,
    linkExactActiveClass: 'acvice_menu',
})

/*method*/
const contains = function (needle, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === needle) return true;
    }
    return false;
}
router.beforeEach((to, from, next) => {
    if(to.meta.metaTags){
        to.meta.metaTags.forEach(tag => {
            document.head.querySelector(`meta[name='${tag.name}']`).content = tag.content;
        });
    }
    next()

});
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
