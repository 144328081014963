<template>
    <div>
        <!--읽지 않은 메세지-->
        <message-comp></message-comp>
        <div class="footer">
            <div class="footer_cont">
                <div>
                    <img src="../assets/images/logo/ptn/logo02.png" alt="">
                </div>
                <div style="color: #24ae78;padding: 10px">
                    copyright(c) 2020-2023 All rights reserved
                </div>
            </div>
        </div>

    </div>


</template>

<script>
    import MessageComp from "./MessageComp";

    export default {
        name: "FootComp",
        components: {MessageComp},
        created() {
        }
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        padding: 0px 320px 16px 320px;
        color: #fff9ff;
    }

    .footer .footer_cont {
        width: 100%;
        border-top: 2px solid #393838;
        background-color: var(--footBg);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 10px;
    }

    .footer .footer_cont img{
        max-height: 100px;
    }

    .footer .footer_cont .footer_left {
        width: 50%;
        box-sizing: border-box;
        padding: 0 32px;
    }

    .footer .footer_cont .footer_right {
        width: 50%;
        box-sizing: border-box;
        padding: 0 32px;
    }

    .footer .footer_cont .footer_bottom {
        width: 100%;
        padding: 32px 32px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .footer .footer_cont .footer_bottom .bitem{
        width: 25%;
    }
    .footer .footer_cont .footer_bottom .bitem p{
        padding: 3px 0;
        color: #a3a1a7;
    }
    .footer .footer_cont .footer_bottom .bitem .title{
        color: #fff9ff;
    }

</style>