<template>
    <div class="menu-items">

        <router-link tag="div" to="/recharge" class="m-item mrecharge"></router-link>

        <router-link tag="div" to="/exchange" class="m-item mexchange"></router-link>

        <router-link tag="div" to="/customer_center" class="m-item mcustomer"></router-link>


        <router-link tag="div" to="/culcheck" class="m-item mculcheck"></router-link>


        <router-link tag="div" to="/sports" class="m-item msports"></router-link>

        <router-link tag="div" to="/sports_inplay" class="m-item minplay"></router-link>

        <router-link tag="div" to="/sports_result" class="m-item mgameresult"></router-link>

        <router-link tag="div" to="/sports_betinfo" class="m-item msbetinfo"></router-link>


        <router-link tag="div" to="/casinohonor" class="m-item mcasino"></router-link>

        <router-link tag="div" :to="{path:'/leisure/eospowerball1m'}" class="m-item mminigame"></router-link>

        <router-link tag="div" to="/leisure/bet365_superleague" class="m-item mbet365"></router-link>

<!--        <router-link tag="div" to="/tgame365/tgame365highlow5s" class="m-item mtgame"></router-link>-->

        <router-link tag="div" to="/notice" class="m-item mnotice"></router-link>

        <router-link tag="div" to="/event" class="m-item mevent"></router-link>

        <router-link tag="div" to="/coupon" class="m-item mcoupon"> </router-link>
        <router-link tag="div" to="/message" class="m-item mmessage"> </router-link>
        <router-link tag="div" to="/recmember" class="m-item mrecmember"> </router-link>

<!--        <router-link tag="div" to="/sports_result" class="m-item mgameresult"></router-link>-->

<!--        <router-link tag="div" to="" class="m-item"></router-link>-->

<!--        <router-link tag="div" to="" class="m-item"></router-link>-->

    </div>
</template>

<script>
    export default {
        name: "MobileMenuItemsComp"
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: start;
        flex-wrap: wrap;
        height: 480px;
    }

    .m-item {
        width: 22.5%;
        height: 110px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        border-radius: 5px;
    }


    .msports {
        background: url(../assets/images/bg/mobile_menu/ptn/sports.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mcustomer {
        background: url(../assets/images/bg/mobile_menu/ptn/customer.jpg) center center no-repeat;
        background-size: 100%;
    }

    .minplay {
        background: url(../assets/images/bg/mobile_menu/ptn/inplay.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mminigame {
        background: url(../assets/images/bg/mobile_menu/ptn/minigame.jpg) center center no-repeat;
        background-size: 100%;
    }


    .mnotice {
        background: url(../assets/images/bg/mobile_menu/ptn/notice.jpg) center center no-repeat;
        background-size: 100%;
    }

    .msbetinfo {
        background: url(../assets/images/bg/mobile_menu/ptn/betinfo.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mevent {
        background: url(../assets/images/bg/mobile_menu/ptn/event.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mculcheck {
        background: url(../assets/images/bg/mobile_menu/ptn/culcheck.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mrecharge {
        background: url(../assets/images/bg/mobile_menu/ptn/recharge.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mexchange {
        background: url(../assets/images/bg/mobile_menu/ptn/exchange.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mcasino {
        background: url(../assets/images/bg/mobile_menu/ptn/casino.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mgameresult {
        background: url(../assets/images/bg/mobile_menu/ptn/gameresult.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mbet365 {
        background: url(../assets/images/bg/mobile_menu/ptn/bet365.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mtgame {
        background: url(../assets/images/bg/mobile_menu/ptn/tgame.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mcoupon {
        background: url(../assets/images/bg/mobile_menu/ptn/coupon.jpg) center center no-repeat;
        background-size: 100%;
    }

    .mrecmember {
        background: url(../assets/images/bg/mobile_menu/ptn/recmember.jpg) center center no-repeat;
        background-size: 100%;
    }
    .mmessage {
        background: url(../assets/images/bg/mobile_menu/ptn/message.jpg) center center no-repeat;
        background-size: 100%;
    }



</style>