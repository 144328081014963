import http from "./axios/http";

export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/honor/get_lobby_url?t=' + new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}
export function getGameUrl(gameId,provider) {
  return http.get('/casino/honor/get_game_url?t=' + new Date().getTime(), {'gameId':gameId,'provider':provider})
}
export function getGameUrl2(gameId,vendor) {
  return http.get('/casino/honor/get_game_url2?t=' + new Date().getTime(), {'gameId':gameId,'vendor':vendor})
}
export function getGameList(vendor) {
  return http.get('/casino/honor/get_game_list?t=' + new Date().getTime(), {'vendor':vendor})
}
export function getAccountBalance() {
  return http.get('/casino/honor/get_account_balance?t=' + new Date().getTime(), {})
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/honor/add_member_casino_cash?t=' + new Date().getTime(), {'amount':amount})
}
export function subjectMemberCasinoCash() {
  return http.get('/casino/honor/subject_member_casino_cash?t=' + new Date().getTime())
}
export function getCasinoMaintaining() {
  return http.get('/casino/honor/get_casino_maintaining?t=' + new Date().getTime())
}
export function getHonorBetList(pageNum, pageSize) {
  return http.get('/casino/honor/get_honor_bet_list?t=' + new Date().getTime() + '&pageNum=' + '&pageSize=' + pageSize)
}

export function deleteAllHonorBet() {
  return http.get('/casino/honor/delete_all?t=' + new Date().getTime())
}
