<template>
    <div class="banner">
        <div style="margin-top: 10px">
            <a href="http://잠자리주소.com" target="_blank">
                <img alt="" src="../assets/images/banner/ptn/www.jpg" style="width: 100%">
            </a>
        </div>
        <div style="margin-top: 10px">
            <a href="https://t.me/zzz7979cs" target="_blank">
                <img alt="" src="../assets/images/banner/ptn/tel.jpg" style="width: 100%">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner {
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>