<template>
    <div class="header">
        <div class="mobile-menu-left-icon micon" @click="showMobileTopMenu">
            <img src="../assets/images/icon/common/menu1_y.svg" alt="">
        </div>
        <div class="menu1">
            <div class="top1">
                <div class="logo" @click="toMain">
                    <div>
                        <img src="../assets/images/logo/ptn/logo01.png" alt="">
                    </div>
                </div>
                <div class="linemessge">
                    <div class="top1_links">
                        <router-link tag="button" :to="{path: '/notice', query: {t: new Date().getTime()}}">
                            <i class="fa fa-rss-square"></i> 공지사항
                        </router-link>
                        <router-link tag="button" :to="{path: '/recharge', query: {t: new Date().getTime()}}">
                            <i class="fa fa-plus-square"></i> 입금
                        </router-link>
                        <router-link tag="button" :to="{path: '/exchange', query: {t: new Date().getTime()}}">
                            <i class="fa fa-minus-square"></i> 출금
                        </router-link>
                        <router-link tag="button" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                            <i class="fa fa-question-circle-o"></i> 고객센터
                        </router-link>
                        <router-link tag="button" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">
                            <i class="fa fa-calendar"></i> 출석부
                        </router-link>
                        <router-link tag="button" :to="{path: '/coupon', query: {t: new Date().getTime()}}">
                            <i class="fa fa-ticket"></i> 쿠폰
                        </router-link>
                        <router-link tag="button" :to="{path: '/recmember', query: {t: new Date().getTime()}}">
                            <i class="fa fa-user-circle"></i> 추천인정보
                        </router-link>
                    </div>
                    <marquee class="marquee">
                        {{lineMessage}}
                    </marquee>
                </div>
            </div>
        </div>
        <div class="menu2">
            <div class="top2">
                <router-link :to="{path: '/sports', query: {t: new Date().getTime()}}" tag="a" class="item"
                             @click.native="changeSportsDisplay(cross)">스포츠
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}" tag="a" class="item">
                    <!--                    인플레이-->
                    INPLAY
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/casinohonor', query: {t: new Date().getTime()}}" tag="a" class="item">카지노&슬롯
                </router-link>
                <div class="dote"></div>


                <router-link :to="{path: '/leisure/eospowerball1m', query: {t: new Date().getTime()}}" tag="a"
                             class="item">
                    미니게임
                </router-link>

                <div class="dote"></div>

                <router-link :to="{path: '/leisure/bet365_superleague', query: {t: new Date().getTime()}}" tag="a"
                             class="item">
                    BET365
                </router-link>
                <div class="dote"></div>

                <!--                <router-link :to="{path: '/tgame365/tgame365highlow5s', query: {t: new Date().getTime()}}" tag="a"-->
                <!--                             class="item">-->
                <!--                    토큰게임-->
                <!--                </router-link>-->
                <!--                <div class="dote"></div>-->

                <router-link :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}" tag="a" class="item">베팅내역
                </router-link>
                <div class="dote"></div>


                <router-link :to="{path: '/notice', query: {t: new Date().getTime()}}" tag="a" class="item">공지사항
                </router-link>
                <div class="dote"></div>


                <router-link :to="{path: '/sports_result', query: {t: new Date().getTime()}}" tag="a" class="item">경기결과
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/customer_center', query: {t: new Date().getTime()}}" tag="a" class="item">
                    고객센터
                </router-link>
                <div class="dote"></div>

                <router-link :to="{path: '/event', query: {t: new Date().getTime()}}" tag="a" class="item">이벤트
                </router-link>


            </div>
        </div>

        <div class="mobile_linemessage">
            <marquee class="marquee">
                {{lineMessage}}
            </marquee>
            <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;height: 36px;line-height: 36px;color: #dfdfdf;font-size: 12px;background-color: #1f1f24">
                <div style="flex: 1;text-align: center">
                    <img :src="$store.state.userInfo.rank.imageUrl" style="height: 20px">
                    <span class="txt-nickname">{{$store.state.userInfo.nickname}}</span>
                </div>
                <div style="flex: 1;text-align: center;">
                    <i class="fa fa-krw"></i> <span
                        class="txt-cash">{{$store.state.userInfo.cash|comma}}</span>
                </div>

                <div style="flex: 1;text-align: center">
                    <i class="fa fa-database"></i> <span
                        class="txt-cash">{{$store.state.userInfo.userPoint|comma}}</span>
                </div>

            </div>
        </div>
        <div class="mobile-menu-right-icon micon">
            <img src="../assets/images/icon/common/login1_y.svg" class="" alt="" v-if="!$store.state.isLogin"
                 @click="showLoginPanel()">
            <img src="../assets/images/icon/common/user1_y.svg" class="" alt="" v-if="$store.state.isLogin"
                 @click="rightBarHandle">
        </div>
        <!--모바일 메뉴-->
        <mobile-top-menu></mobile-top-menu>
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {getLineMessage, getSiteStatus, logout} from "../network/userRequest";
    import {INIT_USERINFO} from "../store/mutation-types";

    export default {
        name: "TopbarComp",
        components: {MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                lineMessage: '',
            }
        },
        methods: {
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', !this.$store.state.mobileRightMenuStatus)
                this.$store.state.mobileRightMenuStatus = !this.$store.state.mobileRightMenuStatus
            },
            showLoginPanel() {
                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/login")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            },
            getLineMessage() {
                getLineMessage().then(res => {
                    if (res.data.success) {
                        let lines = res.data.data;
                        lines.map(line => {
                            this.lineMessage = this.lineMessage + '   ' + line.content
                        })
                    }

                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    logout()
                    this.$router.replace("/login")
                    // this.$store.state.isMaintaining = true;
                    // this.$store.state.maintainingMessage = res.data.msg
                    // this.$store.state.isLogin = false;
                    // this.$store.state.isShowLoginPanel = true;
                    // logout()
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            if (this.$store.state.isLogin == false) {
                this.$router.replace("/login")
            }

            this.$store.state.ismobile = this.browser().mobile
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            this.getLineMessage();
            if (this.$store.state.isLogin) {
                this.$store.state.uncheckedMessageCount = 0;
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
            } else {
                this.$store.commit(INIT_USERINFO);
            }
        },
    }
</script>

<style scoped>
    /* 해더 */
    .header {
        width: 100%;
        height: 140px;
        background-color: var(--headerBg);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        transition: width .2s ease-in-out, left .2s ease-in-out;
        z-index: 1010;
    }

    .header .menu1 {
        width: 100%;
        height: 90px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        --border-bottom: 1px solid #575757;
    }

    .header .menu1 .top1 {
        width: 100%;
        height: 100%;
        padding: 10px 336px 10px 336px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }


    .header .menu1 .top1 .logo {
        position: relative;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #fff9ff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .menu1 .top1 img {
        width: 100%;
        max-height: 70px;
    }

    .header .menu1 .top1 .linemessge {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .header .menu1 .top1 .linemessge .top1_links {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px
    }

    .header .menu1 .top1 .linemessge .top1_links button {
        padding: 5px 10px;
        margin: 5px 0 0 5px;
        background-color: var(--btn01Bg);
        color: #fff9ff;
        border-radius: 3px;
    }

    .header .menu1 .top1 .linemessge .top1_links button:hover {
        color: #fcba46;
    }

    @media screen and (max-width: 1920px) {
        .header .menu1 .top1 .logo {
            width: 25%;
        }
    }

    @media screen and (max-width: 1920px) {
        .header .menu1 .top1 .logo {
            width: 25%;
        }

        .header .menu1 .top1 .linemessge {
            width: 75%;
        }
    }

    @media screen and (max-width: 2560px) {
        .header .menu1 .top1 .logo {
            width: 17%;
        }

        .header .menu1 .top1 .linemessge {
            width: 83%;
        }
    }


    .header .menu1 .top1 .links {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 10px 0px;
    }

    .header .menu1 .top1 .links button {
        padding: 5px 10px;
        font-size: 14px;
    }

    .header .menu2 {
        width: 100%;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        box-shadow: -3px 6px 6px -3px rgba(28, 28, 33, 0.6);
        background: linear-gradient(180deg, var(--menu2bg1), var(--menu2bg2));
        --border-top: 2px solid #515151;
    }

    .header .menu2 .top2 {
        width: 100%;
        height: 100%;
        padding: 0 336px 0 336px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .header .menu2 .top2 a {
        padding: 10px 10px;
    }

    .header .menu2 .top2 .item {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
    }

    .header .menu2 .top2 .dote {
        width: 2px;
        height: 12px;
        --background-color: var(--dote);
    }

    .header .mobile_linemessage {
        display: none;
    }

    .header .mobile-menu-left-icon {
        display: none;
    }

    .header .mobile-menu-right-icon {
        display: none;
    }

    .marquee {
        width: 90%;
        line-height: 30px;
        border-radius: 1px;
        box-sizing: border-box;
        padding: 0 5px;
        background-color: #11181e;
        border: 1px solid #26262a;
        color: #fff9ff;
        box-shadow: inset 0 2px 10px rgba(32, 32, 36, 0.92);
    }

    .fly {
        position: absolute;
        top: 0;
        left: 0;
        width: 153px;
        animation: updownmoving 2s ease-in;
        animation-iteration-count: infinite;
    }

    .logoimg2 {
        margin-left: 153px;
    }

    @keyframes updownmoving {
        0% {
            top: 0;
        }
        50% {
            top: -10px;
        }
        100% {
            bottom: 0;
        }
    }
</style>

